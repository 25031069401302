
import { defineComponent, PropType, inject } from 'vue'

import { InventoryRepositoryKey } from '@/data/injectables'
import { InventoryListRecord } from '@/data/inventory/types'

import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import BlockUI from '@/components/BlockUI.vue'
import NavBar from '@/components/navbar/NavBar.vue'
import ListWrapper from '@/components/ListWrapper.vue'
import InventorySummaryItem from '@/views/inventory/InventorySummaryItem.vue'
import InventorySummaryDialog from '@/views/inventory/InventorySummaryDialog.vue'

export default defineComponent({
  components: {
    NavBar,
    InventorySummaryItem,
    ListWrapper,
    InventorySummaryDialog,
    BlockUI,
  },
  props: {
    viewMode: {
      type: String as PropType<string>,
      default: 'P',
    },
    id: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup() {
    const inventoryRepository = inject(InventoryRepositoryKey)
    const toast = useToast()
    const { handleError } = useErrorHandler(toast)
    return { inventoryRepository, handleError, ...toast }
  },
  data() {
    return {
      loading: {
        header: false,
        body: false,
      },
      draining: false,
      error: {
        header: false,
        body: false,
      },
      header: {
        name: '',
        quantity: 0,
      },
      body: [] as InventoryListRecord[],
      inventorySummaryDialogVisible: false,
      selectedInventory: {} as InventoryListRecord,
    }
  },
  computed: {
    addItemLabel(): string {
      return this.viewMode === 'P' ? 'Adicionar peixe' : 'Adicionar a um açude'
    },
    fishId(): number {
      return this.viewMode === 'P' ? this.selectedInventory.id : this.id
    },
    pondId(): number {
      return this.viewMode === 'F' ? this.selectedInventory.id : this.id
    },
    busy(): boolean {
      return this.loading.header || this.loading.body || this.draining
    },
    loaded(): boolean {
      return !this.loading.header && !this.loading.body
    },
  },
  created() {
    this.loadHeader()
    this.loadBody()
  },
  methods: {
    openInventorySummaryDialog(inventory: InventoryListRecord) {
      this.selectedInventory = inventory
      this.inventorySummaryDialogVisible = true
    },
    handleViewHistory() {
      this.$router.push({
        path: '/movements',
        query: {
          fishId: this.fishId,
          pondId: this.pondId,
        },
      })
    },
    handleCreateIn() {
      this.$router.push({
        path: '/movements/create',
        query: {
          fishId: this.fishId,
          pondId: this.pondId,
          action: 'IN',
          scrollPos: 'bottom',
        },
      })
    },
    handleCreatOut() {
      this.$router.push({
        path: '/movements/create',
        query: {
          fishId: this.fishId,
          pondId: this.pondId,
          action: 'OUT',
          scrollPos: 'bottom',
        },
      })
    },
    handleCreateTransfer() {
      this.$router.push({
        path: '/transfers/create',
        query: {
          fishId: this.fishId,
          pondOriginId: this.pondId,
          quantity: this.selectedInventory.quantity,
          scrollPos: 'bottom',
        },
      })
    },
    async handleDrainPond(): Promise<void> {
      this.$confirm.require({
        message: 'Confirma o esvaziamento do açude?',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.drainPond()
        },
      })
    },
    addItem() {
      this.$router.push({
        path: '/movements/create',
        query: {
          fishId: this.viewMode === 'P' ? null : this.fishId,
          pondId: this.viewMode === 'F' ? null : this.pondId,
          action: 'IN',
        },
      })
    },
    async drainPond(): Promise<void> {
      try {
        this.draining = true
        await this.inventoryRepository?.drainPond(this.pondId)
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Açude esvaziado com sucesso.',
        })
        this.loadHeader()
        this.loadBody()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.draining = false
      }
    },
    async loadHeader(): Promise<void> {
      try {
        this.loading.header = true
        this.error.header = false
        if (this.viewMode === 'F') {
          const fishSummary = await this.inventoryRepository?.getFishSummary(
            this.fishId
          )
          this.header = {
            name: fishSummary?.fish.name || '',
            quantity: fishSummary?.quantity || 0,
          }
        } else {
          const pondSummary = await this.inventoryRepository?.getPondSummary(
            this.pondId
          )
          this.header = {
            name: pondSummary?.pond.name || '',
            quantity: pondSummary?.quantity || 0,
          }
        }
      } catch (e) {
        this.error.header = true
        this.handleError(e)
      } finally {
        this.loading.header = false
      }
    },
    async loadBody(): Promise<void> {
      try {
        this.loading.body = true
        this.error.body = false
        if (this.viewMode === 'F') {
          const params = { fishId: this.id }
          const inventories = await this.inventoryRepository?.getInventories(
            params
          )
          this.body =
            inventories?.map((record) => ({
              id: record.pond.id,
              name: record.pond.name,
              quantity: record.quantity,
            })) || []
        } else {
          const params = { pondId: this.id }
          const inventories = await this.inventoryRepository?.getInventories(
            params
          )
          this.body =
            inventories?.map((record) => ({
              id: record.fish.id,
              name: record.fish.name,
              quantity: record.quantity,
            })) || []
        }
        console.log(this.body)
      } catch (e) {
        this.error.body = true
        this.handleError(e)
      } finally {
        this.loading.body = false
      }
    },
  },
})
