
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {},
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: [
    'update:visible',
    'view-history',
    'create-in',
    'create-out',
    'create-transfer',
  ],
  computed: {
    visibleComputed: {
      get(): boolean {
        return this.visible
      },
      set(visible: boolean) {
        this.$emit('update:visible', visible)
      },
    },
  },
  methods: {
    handleHistory() {
      this.$emit('view-history')
    },
    handleIn() {
      this.$emit('create-in')
    },
    handleOut() {
      this.$emit('create-out')
    },
    handleTransfer() {
      this.$emit('create-transfer')
    },
    closeModal() {
      this.visibleComputed = false
    },
  },
})
