
import { InventoryListRecord } from '@/data/inventory/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    inventory: {
      type: Object as PropType<InventoryListRecord>,
      required: true,
    },
  },
})
